import React from 'react';
import { Button } from '@mui/material';
import linkStyles from '../../../stylesheets/components/links.module.scss';
import layoutStyles from '../../../stylesheets/components/layout.module.scss';
import classNames from 'classnames';

interface PortalAuxButtonProps {
  id?: string;
  dataId?: string;
  onClick: () => void;
  children: React.ReactNode;
  fullWidth?: boolean;
  extraClasses?: string[];
}

const PortalAuxButton = ({
  id,
  dataId,
  onClick,
  children,
  // wasn't sure how to do use same component but not have it be full width
  // we could use an additionalClassNames prop instead, but would be bad to have to pass that in everywhere else
  // we could also use a different component, but that wouldn't be clean as the rest is the same
  fullWidth = true,
  extraClasses = [],
}: PortalAuxButtonProps) => {
  const extraClassesObject = Object.fromEntries(
    extraClasses.map((className) => [className, true])
  );
  return (
    <Button
      id={id}
      data-id={dataId}
      className={classNames(
        linkStyles.link,
        linkStyles.grey,
        {
          [layoutStyles.fullWidth]: fullWidth,
        },
        extraClassesObject
      )}
      onClick={onClick}
    >
      {children}
    </Button>
  );
};

export default PortalAuxButton;
